<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Detail Coupon</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading"
                            class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="editCoupon">
                                <label>Whitelist Paket*</label>
                                <multiselect v-model="form.whitelist" tag-placeholder="Pilih Whitelist Paket"
                                    placeholder="Cari Paket" label="name" track-by="id" :options="packages"
                                    :multiple="true"></multiselect>
                                <br />
                                <label>Nama Coupon*</label>
                                <input type="text" class="form-control" v-model="form.name" required />
                                <br>
                                <label>Kode Coupon*</label>
                                <input type="text" class="form-control" v-model="form.code" required />
                                <br>
                                <label>Diskon</label>
                                <input type="number" class="form-control" v-model="form.discount" required />
                                <br>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" v-model="form.mode" value="1" id="mode" checked>
                                    <label class="form-check-label" for="mode">
                                        Potongan Nominal
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" v-model="form.mode" value="2" id="mode2">
                                    <label class="form-check-label" for="mode2">
                                        Potongan Persen
                                    </label>
                                </div>
                                <br>
                                <label for="">Max Kuota *</label>
                                <input type="number" class="form-control" v-model="form.max_quota" required>
                                <br>
                                <label for="">Expired</label>
                                <v-date-picker class="inline-block h-full" v-model="form.expired_at" mode="date">
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <div>
                                            <input @focus="togglePopover()" :value="inputValue" class="form-control" />
                                        </div>
                                    </template>
                                </v-date-picker>
                                <br>
                                <div class="form-check">
                                    <input class="form-check-input" v-model="form.new_member" type="checkbox" value="1"
                                        id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        New Member Only
                                    </label>
                                </div>
                                <br>
                                <div class="button-group d-flex pt-25">
                                    <button type="submit"
                                        class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                                        simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'

    export default {
        components: {
            Multiselect
        },
        data() {
            return {
                id: '',
                form: {},
                packages: [],
                isLoading: true,
            };
        },
        async mounted() {
            await this.loadData()
            await this.getPackage()
            this.id = this.$route.query.id
        },
        methods: {
            async editCoupon(){
                try{
                    this.form.discount = parseInt(this.form.discount)
                    this.form.max_quota = parseInt(this.form.max_quota)
                    this.form.mode = parseInt(this.form.mode)
                    this.form.new_member = this.form.new_member ? 1 : 0
                    this.form.expired_at = parseInt(moment(this.form.expired_at).utc().valueOf())

                    let whitelist = []
                    for (let i = 0; i < this.form.whitelist.length; i++) {
                        whitelist.push({id: this.form.whitelist[i].id})
                    }

                    this.form.whitelist = whitelist

                    let response = await this.$store.dispatch('admin_coupon_update', this.form)
                    this.$toast.success(response.data.message);

                    await this.loadData()
                    await this.getPackage()
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            async loadData() {
                try {
                    let id = this.$route.query.id
                    let detail = await this.$store.dispatch("admin_coupon_detail", {
                        id: id
                    })
                    this.form = detail.data.data.coupon
                    this.form.whitelist = JSON.parse(this.form.whitelist)

                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/admin/coupon")
                }
            },
            async getPackage() {
                try {
                    let p = await this.$store.dispatch('package_list')
                    this.packages = p.data.data.packages ?? []

                    //PARSE

                    let whitelist = []
                    // console.log(this.form.whitelist)
                    for (let i = 0; i < this.form.whitelist.length; i++) {
                        for (let j = 0; j < this.packages.length; j++) {
                            if(this.packages[j].id == this.form.whitelist[i].id){
                                whitelist.push(this.packages[j])
                            }
                        }                        
                    }

                    this.form.whitelist = whitelist
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
        },
    };
</script>

<style scoped>
    
</style>