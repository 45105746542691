import { render, staticRenderFns } from "./MessagesUser.vue?vue&type=template&id=0d13247b&scoped=true"
import script from "./MessagesUser.vue?vue&type=script&lang=js"
export * from "./MessagesUser.vue?vue&type=script&lang=js"
import style0 from "./MessagesUser.vue?vue&type=style&index=0&id=0d13247b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d13247b",
  null
  
)

export default component.exports