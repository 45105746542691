<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List User
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <a v-on:click="exportUsers" class="btn px-15 btn-secondary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-download fs-16"></i>
                                Export Users
                            </a>
                            <download-csv ref="exportNow" :data="exportJson" name="whacenter_users.csv"
                                style="display: none"></download-csv>
                            <a v-on:click="toggleModal('new_user')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-plus fs-16"></i>
                                Tambah User
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <UserTable/>
                    </div>
                </div>
            </div>
            <modal name="new_user" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddUser/>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import UserTable from "@/components/tables/User.vue";
    import AddUser from "@/components/forms/user/Add.vue";
    import moment from 'moment'

    export default {
        components: {
            UserTable,
            AddUser
        },
        data() {
            return {
                exportJson: []
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
        },
        methods: {
            async exportUsers() {
                try {
                    this.$toast.info("Mohon Tunggu...");

                    await this.$store.dispatch('user_export')
                        .then((response) => {
                            let rawData = response.data.data.users
                            let final = []
                            for (let i = 0; i < rawData.length; i++) {
                                if (rawData[i].phone.startsWith('0')) {
                                    rawData[i].phone = '62' + rawData[i].phone.substr(1)
                                }

                                final.push({
                                    "ID": rawData[i].id,
                                    "Name": rawData[i].name,
                                    "Email": rawData[i].email,
                                    "Username": rawData[i].username,
                                    "Phone": rawData[i].phone,
                                    "Balance": rawData[i].balance,
                                    "Withdrawable Balance": rawData[i].withdrawable_balance,
                                    "Package":  rawData[i].package_name,
                                    "Expired At":  moment.utc(rawData[i].expired_at).local().format("YYYY-MM-DD"),
                                    "Role":  rawData[i].level,
                                    "Status":  rawData[i].status,
                                    "Owner":  rawData[i].owner,
                                })
                            }

                            this.exportJson = final
                        })
                        .then(() => {
                            this.$refs.exportNow.$el.click()
                        })
                } catch (err) {
                    this.$toast.error(err);
                }
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
        },
    };
</script>
