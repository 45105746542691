<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Detail Paket</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading"
                            class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="editPackage">
                                <label for="">Nama Paket</label>
                                <input type="text" class="form-control" v-model="form.name" placeholder="Nama Paket" required />
                                <br>
                                <label for="">Jumlah Lisensi</label>
                                <input type="number" class="form-control" v-model="form.lisensi" required />
                                <div v-if="form.lisensi > 1">
                                    <br>
                                    <label>Allow Paket Agensi*</label>
                                    <multiselect v-model="form.whitelist" tag-placeholder="Pilih Whitelist Paket"
                                            placeholder="Cari Paket" label="name" track-by="id" :options="packages"
                                            :multiple="true"></multiselect>
                                </div>
                                <br>
                                <label for="">Kuota Pesan</label>
                                <input type="number" class="form-control" v-model="form.message_quota" required />
                                <br>
                                <label for="">Maksimal Device</label>
                                <input type="number" class="form-control" v-model="form.max_device" required />
                                <br>
                                <label for="">Maksimal CS</label>
                                <input type="number" class="form-control" v-model="form.max_cs" required />
                                <br>
                                <label for="">Maksimal Campaign</label>
                                <input type="number" class="form-control" v-model="form.max_campaign" required />
                                <br>
                                <label for="">Maksimal Order Online</label>
                                <input type="number" class="form-control" v-model="form.max_order_online" required />
                                <br>
                                <label for="">Maksimal Product Link Rotator</label>
                                <input type="number" class="form-control" v-model="form.max_link_rotator" required />
                                <br>
                                <label for="">Maksimal Chat Widget</label>
                                <input type="number" class="form-control" v-model="form.max_chat_widget" required />
                                <br>
                                <div class="form-check">
                                    <input class="form-check-input" v-model="form.is_addon" type="checkbox" value="1"
                                        id="flexCheckDefault2">
                                    <label class="form-check-label" for="flexCheckDefault2">
                                        Add On
                                    </label>
                                </div>
                                <div v-if="!form.is_addon">
                                    <br>
                                    <label for="">Durasi (Hari)</label>
                                    <input type="number" class="form-control" v-model="form.duration" required />
                                </div>
                                <br>
                                    <label for="">Harga (Rupiah)</label>
                                    <input type="number" class="form-control" v-model="form.price" required />
                                <br>
                                <div class="form-check">
                                    <input class="form-check-input" v-model="form.hide" type="checkbox" value="1"
                                        id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Sembunyikan
                                    </label>
                                </div>
                                <br>
                                <div class="button-group d-flex pt-25">
                                    <button type="submit"
                                        class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                                        simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        components: {
            Multiselect
        },
        data() {
            return {
                id: '',
                form: {
                    name: null,
                    lisensi: 1,
                    message_quota: null,
                    max_device: null,
                    max_cs: null,
                    max_campaign: null,
                    max_order_online: null,
                    max_link_rotator: null,
                    max_chat_widget: null,
                    is_addon: false,
                    duration: null,
                    price: null,
                    hide: false,
                    whitelist: [],
                    webhook_media_quota: null,
                },
                packages: [],
                isLoading: true,
            };
        },
        async mounted() {
            await this.loadData()
            await this.getPackage()
            this.id = this.$route.query.id
        },
        methods: {
            async editPackage(){
                try{
                    let req = {
                        id: this.id,
                        name: this.form.name,
                        lisensi: parseInt(this.form.lisensi ?? 0),
                        message_quota: parseInt(this.form.message_quota ?? 0),
                        max_device: parseInt(this.form.max_device ?? 0),
                        max_cs: parseInt(this.form.max_cs ?? 0),
                        max_campaign: parseInt(this.form.max_campaign ?? 0),
                        max_order_online: parseInt(this.form.max_order_online ?? 0),
                        max_link_rotator: parseInt(this.form.max_link_rotator ?? 0),
                        max_chat_widget: parseInt(this.form.max_chat_widget ?? 0),
                        is_addon: this.form.is_addon ? 1 : 0,
                        duration: parseInt(this.form.duration ?? 0),
                        price: parseInt(this.form.price ?? 0),
                        hide: this.form.hide ? 1 : 0,
                        webhook_media_quota:parseInt(this.form.webhook_media_quota)*1000000 ?? 0
                    }

                    let whitelist = []
                    for (let i = 0; i < this.form.whitelist.length; i++) {
                        whitelist.push({
                            id: this.form.whitelist[i].id
                        })
                    }

                    req.whitelist = whitelist

                    let response = await this.$store.dispatch('admin_package_update', req)
                    this.$toast.success(response.data.message);

                    await this.loadData()
                    await this.getPackage()
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            async loadData() {
                try {
                    let id = this.$route.query.id
                    let detail = await this.$store.dispatch("admin_package_detail", {
                        id: id
                    })
                    this.form = detail.data.data.package
                    this.form.webhook_media_quota = this.form.webhook_media_quota/1000000
                    this.form.whitelist = JSON.parse(this.form.data) ?? []

                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/admin/coupon")
                }
            },
            async getPackage() {
                try {
                    let p = await this.$store.dispatch('package_list')
                    this.packages = p.data.data.packages ?? []

                    //PARSE

                    let whitelist = []
                    for (let i = 0; i < this.form.whitelist.length; i++) {
                        for (let j = 0; j < this.packages.length; j++) {
                            if(this.packages[j].id == this.form.whitelist[i].id){
                                whitelist.push(this.packages[j])
                            }
                        }                        
                    }

                    this.form.whitelist = whitelist
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
        },
    };
</script>

<style scoped>
    
</style>
